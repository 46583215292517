import { PublishContext, EditMiniApplication, AppImage } from "./types";

type UploadResponse = {
  data: {
    url: string
  },
  status: "success"|"error"
}

class Application implements EditMiniApplication {
  async onStart(context: PublishContext) {
    console.log('application started');
    return context;
  }

  async initUI(context: PublishContext) {
    console.log('application init UI');
    const { createElement } = context

    createElement('select', {
      id: 'select',
      options: [2, 4, 6, 8].map((i) => ({
        children: `${i}x`,
        value: i,
      })),
    })

    createElement('input', {
      id: 'originalImage',
      type: 'file',
    })

    return context;
  }

  async execute(context: PublishContext) {
    console.log('execute', context)
    const { userSettings, createElement } = context
  
    switch (userSettings.click) {
      case 'apply': {  
        const { originalImage } = context
        const upscaleFactor = document.querySelector('#select button').innerText.slice(0, 1)
        const src = await uploadImage(originalImage, upscaleFactor)
        createElement('image', {
          id: 'result',
          title: 'publish',
          src, 
        })
        break
      } 
      default:
        break
    }

    switch (userSettings.change) {
      case 'originalImage': {
        const file = document.getElementById('originalImage').files[0]
        const src = URL.createObjectURL(file)
        createElement('image', {
          id: 'original',
          src,
          style: { width: '100px' },
        })

        createElement('button', {
          id: 'apply',
          title: 'Upscale Image',
        })

        return { ...context, originalImage: file }
      }
      default:
        break
    }

    return context
  }

  async onFinish(context: PublishContext) {
    console.log('application finished');
    return context;
  }
}

export default new Application();

const uploadImage = async (image, upscaleFactor) => {
  const data = new FormData()
  data.append('image', image)
  data.append(
    'options',
    JSON.stringify({ upscale_factor: `x${upscaleFactor}` }),
  )

  try {
    const response = await fetch(
      'http://34.139.190.38:4000/api/items/enhance/upscale',
      {
        method: 'POST',
        body: data,
      },
    )
    const jsonData = await response.json()

    if (jsonData.status === 'success') {
      return jsonData.data?.url
    }

    throw new Error('Upload error')
  } catch (error) {
    throw new Error('Upload error')
  }
}
