import { EditContext } from './types';

import contextJSON from './context.json';
import application from './application';
window.application = application;

let context: EditContext = contextJSON;

async function run() {
  console.log('starting context', context);

  window.application.onStart && (context = await window.application.onStart(context));
  context = await window.application.execute(context);
  window.application.onFinish && (context = await window.application.onFinish(context));

  console.log('final context', context);
}

run().catch(err => console.error('an error has occurred', err));
